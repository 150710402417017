<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Detail Approval Pembelian</h5>
            </template>
            <b-row align-h="between">
                <b-col sm="4">
                    <img :src="$logoPath" width="200px">
                </b-col>
                <b-col sm="4">
                    <p><strong>Invoice : {{ noInvoice }} </strong></p>
                    <p>Tanggal : {{ tanggal }} </p>

                    <b-row v-if="flag == 6">
                        <b-button class="mr-2" variant="pgiBtn-danger" name="resetTableBtn" :disabled="isBusy"
                            @click="$bvModal.show('m-confirm-void')">Setuju Void</b-button>
                        <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                            @click="$bvModal.show('m-confirm-batal')">Batalkan
                            Void</b-button>
                    </b-row>

                    <p v-if="flag == 4" class="btn btn-primary">{{ statusFlag }}</p>
                    <p v-if="flag == 1" class="btn btn-primary">Void Ditolak</p>

                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" sm="3">
                    <p><strong>From</strong></p>
                    <p>{{ supplier.nama }}</p>
                </b-col>
                <b-col cols="6" sm="3">
                    <p><strong>To</strong></p>
                    <p>Pusat Gadai Indonesia </p>
                    <p>Jl. Arteri Klapa Dua Kav.12A, Kebon Jeruk</p>
                </b-col>
            </b-row>
            <br>
            <b-row>
                <b-col cols="6" sm="3">
                    <strong>Total </strong>
                    <br>
                    <strong>Ongkir </strong>
                </b-col>
                <b-col cols="6" sm="2">
                    <strong>:</strong> RP. {{ total_pembelian }}
                    <br>
                    <strong>:</strong> RP. {{ ongkir }}
                    <hr>
                    <strong>RP. {{ total }}</strong>
                </b-col>
            </b-row>


            <hr>
            <b-row>
                <b-col>
                    <div class="search-wrapper d-flex align-items-center justify-content-between mb-2">
                        <b-form-group label="Filter" label-for="filterInput"
                            class="form-inline form-group-wrapper mb-3">
                            <div class="d-flex search-input-wrapper">
                                <b-form-input class="ml-2 mr-2" name="filterTable" id="filterInput" type="search"
                                    placeholder="Type to search"></b-form-input>
                                <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="isBusy"
                                    @click="onFilter(filter)">
                                    <i class="fa fa-search"></i>
                                </b-button>
                                <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                                    @click="onReset()">Reset</b-button>
                            </div>
                        </b-form-group>
                    </div>
                </b-col>
            </b-row>

        
            <b-row v-if="flag == 2">
                <b-col class="d-flex justify-content-end my-2">
                    <b-button variant="pgiBtn" class="mr-2" @click="$bvModal.show('m-create')">
                        <i class="fa fa-check"></i> Terima Barang
                    </b-button>
                </b-col>
            </b-row>

            <b-table bordered hover show-empty :busy="isBusy" :items="detailBarang" :fields="fields" responsive
                class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>
                <template v-slot:cell(kelengkapan)="row">
                    <ul class="list-none-wrapper" v-if="row.item.kelengkapan.length > 0">
                        <li v-for="kelengkapan in row.item.kelengkapan" v-bind:key="kelengkapan.id">
                            <span>
                                <i class="fa fa-check" v-if="(kelengkapan.isActive == true)"></i>
                                <i class="fa fa-times" v-if="(kelengkapan.isActive == false)"></i>
                                {{ kelengkapan.name }}
                            </span>
                        </li>
                    </ul>
                    <span v-else>-</span>
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">

                        <!-- <div v-if="flag == 1">
                            <b-button size="sm" v-if="row.item.status != 4" class="mr-3 table-button edit"
                                v-b-tooltip.hover title="Void" name="detailBarangEditBtn">
                                <i class="fa fa-warning text-danger" @click="showModalVoidBarang(`${row.item.id}`)"></i>
                            </b-button>
                            <b-badge variant="danger" v-if="row.item.status == 4">Permintaan Void</b-badge>
                        </div>

                        <b-button v-if="flag == 0" size="sm" class="mr-3 table-button edit" v-b-tooltip.hover
                            title="Delete" name="jenisBarangHapusBtn" @click="showModalHapusBarang(`${row.item.id}`)">
                            <i class="fa fa-trash text-danger"></i>
                        </b-button> -->

                        <div v-if="flag == 6">
                            <b-badge variant="danger" v-if="row.item.status == 4">Permintaan Void</b-badge>
                        </div>

                    </div>
                </template>
            </b-table>
        </b-card>



        <ModalConfirm id="m-confirm-void" title="Confirm Void" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menyetujui permintaan void ini ?" @submit="Void" />

        <ModalConfirm id="m-confirm-batal" title="Confirm Void" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk membatalkan permintaan void ini ?" @submit="batal" />

    </div>
</template>

<script>
export default {
    name: 'detail-approval-pembelian',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isBusy: true,
            isSubmitting: false,
            isLoading: false,
            input: {
                show: 10
            },
            fields: [
                { key: "no", label: "No", sortable: true },
                { key: "namaBarang", label: "Nama Barang", },
                { key: "qty", label: "Qty" },
                { key: "satuan", label: "Satuan" },
                { key: "detail", label: "Detail" },
                { key: "harga", label: "Harga" },
                { key: "totalHarga", label: "Total Harga" },
                { key: "action", label: "Aksi" },
            ],
            filter: [],

            form: {
                id_pembelian: null,
                id_tipe: null,
                harga: null,
                jumlah: null,
                satuan: null,
                imei: "",
                detail_barang: "",
                kode_cabang: null,
                keterangan: ""
            },

            activeId: null,
            total_unit: false,
            idJenis: null,
            idMerk: null,

            total_pembelian: 0,
            ongkir: 0,
            total: 0,
            dropshipper: false,

            cabang: [],
            jenisBarang: [],
            merkBarang: [],
            tipeBarang: [],

            idPembelian: null,
            noInvoice: null,
            tanggal: null,
            supplier: {},
            flag: null,
            statusFlag: "",

            detailBarangAll: [],
            detailBarang: [],
        }
    },
    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            this.initStatus = 0
            this.page = 1
            this.query = "?page=" + this.page

            let id = this.$route.params.id
            this.idPembelian = id

            let getVoidPembelian = this.$axios.get("api/transaksi/pembelian/" + id).then(response => {
                let datas = response.data.data
                if(datas.ongkir == null){
                    datas.ongkir = 0
                }

                this.total_pembelian = datas.total_pembelian.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                this.ongkir = datas.ongkir.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                let total = parseInt(datas.total_pembelian) + parseInt(datas.ongkir)
                this.total = total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                if (response.data.data.total_unit == 0) {
                    this.total_unit = true
                } else {
                    this.total_unit = false
                }

                this.form.id_pembelian = this.$route.params.id
                this.noInvoice = datas.no_invoice
                this.tanggal = datas.tanggal
                this.flag = datas.flag
                this.statusFlag = datas.status_flag

                this.$axios.get("api/supplier/" + datas.id_supplier).then(response => {
                    this.supplier = response.data.data
                })

                if (datas.detail != 0) {
                    let i = 1
                    for (var data of datas.detail) {

                        let harga = data.harga
                        let idrHarga = "Rp. " + harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                        let totalHarga = data.total_harga
                        let idrTotalHarga = "Rp. " + totalHarga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                        this.detailBarang.push({
                            no: i,
                            id: data.id,
                            namaBarang: (data.tipe_barang != null) ?data.tipe_barang.tipe :'-',
                            qty: data.jumlah,
                            satuan: data.satuan,
                            detail: data.detail_barang,
                            harga: idrHarga,
                            totalHarga: idrTotalHarga,
                            status: data.status
                        })

                        i++
                    }
                }
            })

            let getJenisBarang = this.$axios.get("api/admin/barang-jenis/all").then(response => {
                //console.log(response.data.data);
                this.jenisBarang = response.data.data

                //console.log(this.jenisBarang)
            })

            let getCabang = this.$axios.get("api/admin/cabang/all").then(response => {
                //console.log(response.data.data);
                this.cabang = response.data.data
                //console.log(this.cabang);
            })



            Promise.all([getVoidPembelian, getJenisBarang, getCabang]).then(() => {
                this.initStatus = 1
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },


        //-----------------  Fungsi Ambil Data Jenis Barang ----------------- 

        getVoidPembelian() {
            this.$axios.get("api/transaksi/pembelian/" + this.idPembelian).then(response => {
                let datas = response.data.data

                if(datas.ongkir == null){
                    datas.ongkir = 0
                }


                this.total_pembelian = datas.total_pembelian.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                this.ongkir = datas.ongkir.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                let total = parseInt(datas.total_pembelian) + parseInt(datas.ongkir)
                this.total = total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                //console.log(response.data.data.total_unit);
                if (response.data.data.total_unit == 0) {
                    //console.log("total true");
                    this.total_unit = true
                } else {
                    //console.log("total False");
                    this.total_unit = false
                }

                this.detailBarang = []
                this.form.id_pembelian = this.idPembelian
                this.noInvoice = datas.no_invoice
                this.tanggal = datas.tanggal
                this.flag = datas.flag
                this.statusFlag = datas.status_flag
                //console.log(datas);

                if (datas.detail != 0) {
                    let i = 1
                    for (var data of datas.detail) {

                        let harga = data.harga
                        let idrHarga = "Rp. " + harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                        let totalHarga = data.total_harga
                        let idrTotalHarga = "Rp. " + totalHarga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")


                        this.detailBarang.push({
                            no: i,
                            id: data.id,
                            namaBarang: (data.tipe_barang != null) ?data.tipe_barang.tipe :'-',
                            qty: data.jumlah,
                            satuan: data.satuan,
                            detail: data.detail_barang,
                            harga: idrHarga,
                            totalHarga: idrTotalHarga,
                            status: data.status
                        })

                        i++
                    }
                }
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },

        Void() {
            let form = {
                id: this.idPembelian,
                flag: 4
            }
            this.$axios.post("api/transaksi/pembelian/update", form).then(() => {
                this.$bvModal.hide("m-confirm-void")
                this.$helper.toastSucc(this, "Pembelian berhasil dibatalkan")
                this.getVoidPembelian()

            })
        },

        batal() {
            let form = {
                id: this.idPembelian,
                flag: 1
            }
            this.$axios.post("api/transaksi/pembelian/update", form).then(() => {
                this.$bvModal.hide("m-confirm-batal")
                this.$helper.toastSucc(this, "Void berhasil ditolak")

                this.getVoidPembelian()

            })
        },


        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

    },

    watch: {

        //----------------- Pantau Jenis Barang -----------------

        "form.harga": function (newValue) {
            let cleanValue = this.$helper.changeToValue(newValue);
            const result = cleanValue.replace(/(?!^-)[^0-9]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            this.$nextTick(() => this.form.harga = result);
        },

        'idJenis': function (id) {
            this.merkBarang = []
            this.tipeBarang = []
            this.idMerk = null
            this.form.id_tipe = null
            this.form.satuan = null

            this.$axios.get("api/admin/barang-merk" + "?id_jenis=" + id.id).then(response => {
                let datas = response.data.data
                //console.log(datas.data);
                for (var data of datas.data) {
                    this.merkBarang.push({
                        id: data.id,
                        merk: data.merk
                    })
                }
                if (this.isBusy == true) this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    if (this.isBusy == true) this.toggleBusy()
                })
        },

        'idMerk': function (id) {
            this.tipeBarang = []
            this.form.id_tipe = null
            this.form.satuan = null

            this.$axios.get("api/admin/barang-tipe/all" + "?id_merk=" + id.id).then(response => {
                let datas = response.data
                //console.log(datas.data);
                for (var data of datas.data) {
                    this.tipeBarang.push({
                        id: data.id,
                        tipe: data.tipe,
                        satuan: data.satuan
                    })
                }

                if (this.isBusy == true) this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    if (this.isBusy == true) this.toggleBusy()
                })
        },

        'form.id_tipe': function (id) {
            this.form.satuan = id.satuan
        }

    },


    created() {
        this.init()
    }
}
</script>
